import Header from "../header/Header";
import HomeHeroSection from "../section/home/hero/Hero";
import AboutProduct from "../section/home/aboutProduct/AboutProtuct";
import InfoProduct from "../section/home/infoProduct/InfoProduct";
import StepSection from "../section/home/stepsSection/StepSection";
import BuySection from "../section/home/buySection/BuySection";
import Footer from "../footer/Footer";
import BoxInfo from "../section/home/boxInfo/BoxInfo";
import {useState} from "react";

import Popup from "../popup/Popup";
import MmailchimpSubscribe from "../popup/MmailchimpSubscribe";


const HomePage = () => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const openPopup = () => {
        setIsPopupOpen(true);
        document.body.classList.add('disable-scroll');
    };

    const closePopup = () => {
        setIsPopupOpen(false);
        document.body.classList.remove('disable-scroll');
    };

    return (<>
            <Header openPopup={(v) => openPopup(v)} white={true} fixed={true}/>
            <HomeHeroSection openPopup={(v) => openPopup(v)}/>
            <AboutProduct/>
            <InfoProduct/>
            <StepSection/>
            <BuySection openPopup={(v) => openPopup(v)}/>
            <BoxInfo/>
            <Footer/>
            <Popup isOpen={isPopupOpen} onClose={closePopup}>
               <MmailchimpSubscribe closeForm={closePopup}/>
            </Popup>

        </>
    )
}

export default HomePage
