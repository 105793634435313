import './_homeHeroSection.scss'
import bgImage from '../../../../assets/image/hero-bg.jpg'
import android from '../../../../assets/image/android-btn.svg'
import ios from '../../../../assets/image/ios-btn.svg'


const HomeHeroSection = ({openPopup}) => {
    return (<div className='hero-section'>
            <div className='box' style={{backgroundImage: `url(${bgImage})`}}>
                <div className='container'>
                    <div className='box-content'>
                        <h1>Relieve Pivot Worries with Ease!</h1>
                        <div className='box-btn'>
                            <div><a href="/#" className='btn-white' onClick={()=>openPopup(true)}>Sign Up Now</a></div>
                            <div className='box-child'>
                                <div><a href="/#" onClick={()=>openPopup(true)}><img src={android} alt="download android"/></a></div>
                                <div><a href="/#" onClick={()=>openPopup(true)}><img src={ios} alt="download ios"/></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HomeHeroSection
