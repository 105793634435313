import './_stepSection.scss'
import bgSteps from "../../../../assets/image/bgSteps.png";


const StepSection = () => {
    return ( <div className='steps'>
            <div className='container'>
                <div className='bg' style={{backgroundImage: `url(${bgSteps})`}}>
                    <div className='box'>
                        <div className='left-box'>
                            <h2>3 Simple Steps to PivotHawk</h2>
                        </div>
                        <div className='right-box'>
                            <div>
                                <p>1. Step</p>
                                <h4>Order Your Device</h4>
                            </div>
                            <div>
                                <p>2. Step</p>
                                <h4>Replace Your Light Bulb with PivotHawk</h4>
                            </div>
                            <div>
                                <p>3. Step</p>
                                <h4>You're Good to Go!</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StepSection
