import './_buy.scss'
import bgSteps from "../../../../assets/image/bgSteps.png";


const BuySection = ({openPopup}) => {
    return ( <div className='buy'>
            <div className='box'>
                <h2>Join our wait</h2>
                <div>
                    <h2>list <span> now!</span></h2>
                    <button onClick={()=>openPopup(true)}>
                        Sign Up Now
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BuySection
