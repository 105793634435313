import './_LoginPage.scss'
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import {useLocalStorage} from "../../../utils/useLocalStorage";

import {useState} from "react";

import { useNavigate } from 'react-router-dom';
import {Button, TextField} from "@mui/material";

const LoginPage = () => {

    const navigate = useNavigate();

    const [accessToken, setAccessToken] = useLocalStorage("accessToken", "");
    const [refreshToken, setRefreshToken] = useLocalStorage("refreshToken", "");
    const [userID, setUserID] = useLocalStorage("userID", "");

    const [value, setValue] = useState({email: 'test@gmail.com',password:'123123'})

    const handleClick = () => {
        navigate('/dashboard');
    };
    return (<>
            <Header/>
            <div className='login-wrapper'>
                <div className='container'>
                    <div className='box-login'  >
                        <TextField
                            sx={{width:'100%'}}
                            id="standard-helperText"
                            label="Login"
                            required
                            helperText="Enter your login"
                            variant="standard"
                            // defaultValue={value.email}
                            // onChange={(e) => setNewDevice({...newDevice, id: e.target.value})}
                            // value={newDevice.id}
                        />
                        <TextField
                            sx={{width:'100%'}}
                            id="standard-helperText"
                            label="Password"
                            type='password'
                            required
                            helperText="Enter your password"
                            variant="standard"
                            // defaultValue={value.password}
                            // onChange={(e) => setNewDevice({...newDevice, id: e.target.value})}
                            // value={newDevice.id}
                        />
                        {/*<input value={value.email} onChange={(e)=> setValue({email:e.target.value})} type="email" placeholder='You Email'/>*/}
                        {/*<input value={value.password} onChange={(e)=> setValue({password:e.target.value})} type="password" placeholder='You password'/>*/}
                        {/*<button onClick={()=>handleClick()}>Login</button>*/}
                         <div style={{display:'flex', justifyContent:"end",width:'100%'}}>
                             <Button onClick={()=>handleClick()}  variant="text">Login</Button>
                         </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default LoginPage
