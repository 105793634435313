import './_Header.scss'
import whiteLogo from '../../assets/image/white-logo.svg'
import blackLogo from '../../assets/image/black-logo.svg'
import whiteCart from '../../assets/image/cart-white.svg'
import blackCart from '../../assets/image/cart-black.svg'
import whiteUser from '../../assets/image/user-white.svg'
import blackUser from '../../assets/image/user-black.svg'
import {useNavigate} from "react-router-dom";


const Header = ({openPopup,white = false,fixed=false}) => {
    const navigate = useNavigate();

    return (
        <header className={fixed ? 'fixed': 'relative' }>
            <div className='container'>
                <div className='box'>
                    <div className='logo'><a href="/"><img src={white ? whiteLogo : blackLogo} alt="logo"/></a></div>
                    <div className='btn'>
                        <button className={`${ white ?'text-white':'text-blue'} row-btn`} onClick={()=>openPopup(true)}><img src={white ? whiteCart : blackCart} alt="cart"/><span>buy</span></button>
                        <button className={`${ white ?'text-white':'text-blue'} row-btn`} onClick={()=> navigate('/login')}><img src={white ? whiteUser : blackUser} alt="login"/><span>sign in</span></button>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
