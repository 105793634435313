import './_Dashboard.scss'
import Header from "../../header/Header";
import Footer from "../../footer/Footer";
import {
    Box,
    Button,
    Modal,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Cached from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import {useState} from "react";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    height: 180,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


const Dashboard = () => {
    const [rows, setRows] = useState([
        {name: 'Pivot south field', id: 'WXU3QqSD', status: 'on', location: '46.821911548334874,43.53980463819329'},
        {name: 'Pivot New Canada', id: 'ElqwFxtH', status: 'off', location: '23.223440803621344,54.846987704404256'},
    ])
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [newDevice, setNewDevice] = useState({name: '', id: ''});
    const addNewDevice = () => {
        const hasEmptyFields = Object.values(newDevice).some(value => value === '');

        function generateRandomLocation() {
            const latitude = Math.random() * 180 - 90;
            const longitude = Math.random() * 360 - 180;
            return latitude + ',' + longitude;
        }

        if (hasEmptyFields) {
            alert('Oops... something\'s wrong, check all fields!')
        } else {
            setRows([...rows, {...newDevice, status: 'off', location: generateRandomLocation()}])
            setNewDevice({name: '', id: ''})
        }
    }
    return (<>
            <Header/>
            <div className='dashboard-wrapper'>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div
                            component="form"
                            sx={{
                                '& .MuiTextField-root': {m: 1, width: '100px'},
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <div className='flex'>
                                <TextField
                                    id="standard-helperText"
                                    label="Device ID"
                                    required
                                    helperText="Enter your device ID"
                                    variant="standard"
                                    onChange={(e) => setNewDevice({...newDevice, id: e.target.value})}
                                    value={newDevice.id}
                                />
                                <TextField
                                    style={{marginLeft: '5px'}}
                                    id="standard-helperText"
                                    label="Device name"
                                    required
                                    helperText="Name the device."
                                    variant="standard"
                                    onChange={(e) => setNewDevice({...newDevice, name: e.target.value})}
                                    value={newDevice.name}
                                />
                            </div>
                            <div style={{float: 'right', marginTop: '10px'}}>
                                <Button onClick={addNewDevice} variant="outlined" startIcon={<AddIcon/>}>
                                    Add
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Modal>
                <div className='container'>
                    <div className='top-button'>
                        <Stack direction="row" spacing={2}>
                            <Button onClick={handleOpen} variant="outlined" startIcon={<AddIcon/>}>
                                Add
                            </Button>
                            <Button variant="contained" endIcon={<SyncIcon/>}>
                                Sync All
                            </Button>
                        </Stack>
                    </div>
                    <TableContainer>
                        <Table sx={{minWidth: 650}} aria-label="simple table">
                            <TableHead sx={{background: '#dedede'}}>
                                <TableRow>
                                    <TableCell align="left">Pivot Name</TableCell>
                                    <TableCell align="left">Pivot ID</TableCell>
                                    <TableCell align="left">Status</TableCell>
                                    <TableCell align="left">location</TableCell>
                                    <TableCell align="left">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows?.map((row) => (
                                    <TableRow key={row.name} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                        <TableCell align="left">{row.name}</TableCell>
                                        <TableCell align="left">{row.id}</TableCell>
                                        <TableCell align="left">{row.status}</TableCell>
                                        <TableCell align="left">{row.location}</TableCell>
                                        <TableCell align="left">
                                            <div className='flex'>
                                                <div className='pointer' onClick={() => alert('Remove this Pivot?')}>
                                                    <DeleteIcon/>
                                                </div>
                                                <div className='pointer'
                                                     onClick={() => alert('Update data current Pivot?')}>
                                                    <Cached/>
                                                </div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <Footer/>
        </>
    );
};

export default Dashboard
