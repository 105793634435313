import './_Footer.scss'
import logo from '../../../src/assets/image/logo-footer.svg'
import ln from '../../../src/assets/image/mdi_linkedin.svg'
import fb from '../../../src/assets/image/gg_facebook.svg'
import inst from '../../../src/assets/image/ri_instagram-line.svg'
import {useNavigate} from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();
    return (
        <footer>
            <div className='container'>
                <div className='box'>
                    <div className='first-box'>
                        <img src={logo} alt="logo"/>
                        <ul>
                            <li><a href="https://www.linkedin.com/company/pivothawk/" target="_blank"><img src={ln} alt="linkedin"/></a></li>
                            <li><a href=""><img src={fb} alt="facebook"/></a></li>
                            <li><a href=""><img src={inst} alt="instagram"/></a></li>
                        </ul>
                    </div>
                    <div className='nav-box'>
                        <div>
                            <ul>
                                <li><a href="#">Policies & Agreements</a></li>
                                <li><a href="#">FAQ</a></li>
                            </ul></div>
                        <div>
                            <ul>
                                <li><a href="#">Dealer Location</a></li>
                                <li><a href="#">Products And Solutions</a></li>
                            </ul></div>
                        <div>
                            <ul>
                                <li><p>Support: </p></li>
                                <li><a href="mailto:mg@pivothawk.com">mg@pivothawk.com</a></li>
                            </ul></div>
                        <div>
                            <ul>
                                <li><p>For proposals and partnership:  </p></li>
                                <li><a href="mailto:mg@pivothawk.com">mg@pivothawk.com</a></li>
                            </ul></div>
                    </div>
                    <div>
                        <div><button onClick={()=>navigate(`/about`, {replace: true})}>About Company</button></div>
                        <p className='copyright'>© PivotHawk 2023</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
