import './aboutProduct.scss'
import safe from '../../../../assets/image/safe-icon.svg'
import time from '../../../../assets/image/time.svg'
import money from '../../../../assets/image/money.svg'
import repair from '../../../../assets/image/repair.svg'
import heroProduct from '../../../../assets/image/hero-product.png'
import arrowProduct from '../../../../assets/image/arrow-device.svg'


const AboutProduct = () => {
    const scroll = () => {
        window.scrollTo({
            top: 400,
            behavior: 'smooth',
        });
    }
    return (
        <div className='about-product'>
            <div className='about-product-anchor'>
                <div className='container'>
                    <h3>About The Product</h3>
                    <a onClick={() => scroll()} className='anchor'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="26" viewBox="0 0 16 26" fill="none">
                            <path opacity="0.5"
                                  d="M7.29289 25.7071C7.68342 26.0976 8.31658 26.0976 8.70711 25.7071L15.0711 19.3431C15.4616 18.9526 15.4616 18.3195 15.0711 17.9289C14.6805 17.5384 14.0474 17.5384 13.6569 17.9289L8 23.5858L2.34315 17.9289C1.95262 17.5384 1.31946 17.5384 0.928933 17.9289C0.538409 18.3195 0.538409 18.9526 0.928933 19.3431L7.29289 25.7071ZM7 4.37114e-08L7 25L9 25L9 -4.37114e-08L7 4.37114e-08Z"
                                  fill="white"/>
                        </svg>
                    </a>
                </div>
            </div>
            <div className='about-product-info'>
                <div className='container'>
                    <div className='box'>
                        <div className='left'>
                            <div className='text-box'>
                                <img src={safe} alt="safe"/>
                                <div className='text'>Pivot-Friendly and Safe</div>
                            </div>
                            <div className='text-box'>
                                <img src={time} alt="time"/>
                                <div className='text'>24/7 Pivot Monitoring, Anywhere, Anytime, Using Our App
                                </div>
                            </div>
                        </div>
                        <div className='product-box'>
                            <img className='product' src={heroProduct} alt="product"/>
                            <div className='box-text'>
                                <img src={arrowProduct} alt="arrow"/>
                                <p className='text'>
                                    our device
                                </p>
                            </div>
                        </div>
                        <div className='right'>
                            <div className='text-box'>
                                <img src={money} alt="money"/>
                                <div className='text'>Save Money, Time, and Your Peace of Mind-No More Thousand Field Trips!
                                </div>
                            </div>
                            <div className='text-box'>
                                <img src={repair} alt="repair"/>
                                <div className='text'>
                                    Hassle-Free Installation
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutProduct
