import './_boxInfo.scss'

import arrow from '../../../../assets/image/arrow-right.svg'
import faq from '../../../../assets/image/faq.svg'
import dealer from '../../../../assets/image/dealer.svg'
import solution from '../../../../assets/image/solution.svg'
import {useNavigate} from "react-router-dom";



const BoxInfo = () => {
    const navigate = useNavigate();

    return (             <div className='box-info'>
            <div className='container'>
                <div className='box-container'>
                    <div className='box'>
                        <div>
                            <div className='box-text'>
                                <h3>Have Questions? Checkout our FAQ.</h3>
                                {/*<p>(frequently asked questions)</p>*/}
                            </div>
                            <a><img src={arrow} alt="arrow"/></a>
                        </div>
                        <img src={faq}/>
                    </div>
                    <div className='box'>
                        <div>
                            <div className='box-text'>
                                <h3>Find a Dealer Near You.</h3>
                            </div>
                            <a><img src={arrow} alt="arrow"/></a>
                        </div>
                        <img src={dealer}/>
                    </div>
                    <div className='box'>
                        <div>
                            <div className='box-text'>
                                <h3>Explore Our Range of Products and Solutions.</h3>
                            </div>
                            <a><img src={arrow} alt="arrow"/></a>
                        </div>
                        <img src={solution}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BoxInfo
