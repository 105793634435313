import './_infoProduct.scss'
import desktop from "../../../../assets/image/desktop.png";


const InfoProduct = () => {
    return (<div className='info-product'>
            <div className='container custom-container'>
                <div className="box-img">
                    <div>
                        <img src={desktop}/>
                    </div>
                </div>
                <div className='box-text'>
                    <h3>User-Friendly, on Both Desktop and Mobile Devices</h3>
                    <p>So You Can Keep an Eye on Your Pivots from Anywhere in the World</p>
                </div>
            </div>
        </div>
    )
}

export default InfoProduct
