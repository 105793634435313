import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from "./components/page/Home";
import AboutCompany from "./components/page/AboutCompany/AboutCompany";
import LoginPage from "./components/page/LoginPage/LoginPage";
import {QueryClient, QueryClientProvider} from 'react-query'
import Dashboard from "./components/page/Dashboard/Dashboard";
const App = () => {
    const queryClient = new QueryClient()
    return (
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    {/*<Route path="/faq" element={<Faq/>} />*/}
                    <Route path="/about" element={<AboutCompany/>} />
                    <Route path="/login" element={<LoginPage/>} />
                    <Route path="/dashboard" element={<Dashboard/>} />
                </Routes>
                </QueryClientProvider>
            </BrowserRouter>

    );
};


export default App;
